@tailwind utilities;

@layer utilities {
  .tooltip {
    @apply relative px-3 py-2 w-fit h-fit text-b2b rounded-s bg-bg-white shadow-xs;
  }

  .tooltip::before {
    content: '';
    background-color: theme('colors.bg.white');
    position: absolute;
    border-radius: 0;
    height: 8px;
    width: 8px;
  }
  .tooltip-top::before {
    @apply top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45;
  }

  .tooltip-bottom::before {
    @apply bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 rotate-45;
  }

  .tooltip-left::before {
    @apply left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45;
  }

  .tooltip-right::before {
    @apply right-0 top-1/2 translate-x-1/2 -translate-y-1/2 rotate-45;
  }
}
