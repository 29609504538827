/* reset.css */

h1,
h2,
h3,
h4,
h5,
h6,
b1,
b2,
b3,
p,
blockquote,
pre,
ol,
ul,
li,
figure,
figcaption,
hr,
dd,
dl,
dt,
a,
abbr,
address,
b,
strong,
code,
em,
i,
mark,
q,
s,
small,
span,
sub,
sup,
time,
var,
bdi,
bdo,
button,
label,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  line-height: inherit;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea,
select {
  background: none;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
}

hr {
  border: none;
  height: 1px;
  background-color: currentColor;
}
